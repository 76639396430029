const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = [];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern', 'wheel_offset'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields, 'Universal'];

const categorySelectionPageUrl = '/categories/';
const brandSelectionPageUrl = '/brands/';
const isCategoryPage = window.document.body.classList.contains('category');
const isVehicleLandingPage = !!window.document.querySelector('#cm_landing-page');
const isVehicleGalleryPage = !!window.document.querySelector('#cm_fitment-gallery');
const isVehicleGalleryDetailsPage =
  !!window.location.pathname.startsWith('/vehicle-gallery/') &&
  window.location.pathname !== '/vehicle-gallery/';

globalThis.Convermax.handleProductCompareClick = () => {
  const productsIdsToCompare = [...window.document.querySelectorAll('input[name="products[]"]:checked')].map(
    (product) => product.dataset.compareId,
  );
  const compareBtn = window.document.body.querySelector('.cm_card-compare_link');
  const compareCount = window.document.body.querySelector('.cm_card-compare_count');
  if (!compareBtn) {
    return;
  }

  compareCount.textContent = productsIdsToCompare.length;
  compareBtn.classList.toggle('show', productsIdsToCompare.length > 1);
  compareBtn.href = `/compare/${productsIdsToCompare.join('/')}/`;
};

function getLocalPreselection(pageType, defaults) {
  if (isVehicleLandingPage) {
    return [{ field: 'Vehicle', term: window.location.pathname }];
  }

  return defaults.getter(pageType);
}

const appendVehicleGalleryLink = (productData, fields, parentSelector) => {
  const parentElem = window.document.querySelector(parentSelector);
  if (!parentElem) {
    return;
  }

  const dataEntries = Object.entries(productData)
    .filter(([field]) => fields.includes(field))
    .map(([field, value]) => ({ field, value }));

  if (!dataEntries.length) {
    return;
  }

  const mapField = (field) => {
    switch (field) {
      case 'vehicle_year':
        return 'Year';
      case 'vehicle_make':
        return 'Make';
      case 'vehicle_model':
        return 'Model';
      case 'wheel_brand':
      case 'tire_brand':
        return 'brand_name';
      default:
        return field;
    }
  };

  const url = window.Convermax.createSearchUrl(
    {
      selection: dataEntries.flatMap(({ field, value }) => {
        const mappedField = mapField(field);
        return Array.isArray(value)
          ? value.map((v) => ({ field: mappedField, term: v }))
          : { field: mappedField, term: value };
      }),
    },
    { goToSearchPage: true },
  );

  if (!url) {
    return;
  }

  const linkElem = window.document.createElement('a');
  linkElem.className = 'cm_vehicle-gallery-link';

  linkElem.onclick = () => {
    window.Convermax.discardVehicle();
    window.open(url, '_blank');
  };

  const linkText = document.createTextNode('Browse products');
  linkElem.appendChild(linkText);

  parentElem.appendChild(linkElem);
};

function AfterInit() {
  if (!isVehicleGalleryDetailsPage) {
    return;
  }

  const intervalId = setInterval(() => {
    if (window.document.querySelector('#vehicle-gallery-page')) {
      clearInterval(intervalId);

      // "/vehicle-gallery/1/1990-Nissan-Skyline-Falken-KOKORO-MonoC-Coilovers" -> "1"
      const id = window.location.pathname.split('/').filter(Boolean)[1];
      if (!id) {
        return;
      }

      window.Convermax.getProductAsync(id).then((productData) => {
        appendVehicleGalleryLink(
          productData,
          ['vehicle_year', 'vehicle_make', 'vehicle_model'],
          '#vehicle-gallery-page .vehicle-tab',
        );

        appendVehicleGalleryLink(
          productData,
          ['wheel_diameter', 'wheel_width', 'wheel_offset', 'wheel_brand', 'wheel_model'],
          '#vehicle-gallery-page .wheel-tab',
        );

        appendVehicleGalleryLink(
          productData,
          ['tire_width', 'tire_aspect', 'tire_rim', 'tire_brand', 'tire_model'],
          '#vehicle-gallery-page .tire-tab',
        );
      });
    }
  }, 500);

  setTimeout(() => clearInterval(intervalId), 10000);
}

export default {
  storeId: isVehicleGalleryPage || isVehicleGalleryDetailsPage ? 'threepieceus-gallery' : 'threepieceus',
  platform: 'bigcommerce',
  AfterInit,
  getLocalPreselection,
  SearchRequestDefaults: {
    pageSize: 36,
  },
  page: {
    getPageType: (defaults) =>
      window.location.pathname === categorySelectionPageUrl ||
      window.location.pathname === brandSelectionPageUrl
        ? 'parts'
        : defaults.getter(),
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
    isVehicleSelectionIsolated: true,
    forceVehicleSelection: true,
    expectResponseRedirect: true,
    isAutoVehicleSelectionDisabled: isVehicleGalleryPage || isCategoryPage || isVehicleLandingPage,
  },
  facets: {
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['tire_load_index'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: { replace: 'main .page-content' },
      template: 'SearchPage',
      visibleIf: () => window.location.pathname === '/search/',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '.body .page',
      template: 'SearchPage',
      visibleIf: () =>
        window.document.querySelector('#product-listing-container') &&
        window.location.pathname !== categorySelectionPageUrl &&
        window.location.pathname !== brandSelectionPageUrl,
    },
    {
      name: 'VehicleLandingPage',
      type: 'SearchPage',
      location: '#cm_landing-page',
      template: 'SearchPage',
    },
    {
      name: 'FitmentGalleryPage',
      type: 'SearchPage',
      location: '#cm_fitment-gallery',
      template: 'fitmentGallery/SearchPage',
    },
    {
      name: 'SearchHeader',
      sortSelectClass: 'form-select',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Low - High',
        'price:desc': 'Price: High - Low',
      },
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    { name: 'SearchResult' },
    {
      name: 'SearchBox',
      location: '.navUser-item.form-li',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: { firstChildOf: '.header-top' },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'HomeVehicleWidget_tab',
      type: 'VehicleWidget',
      location: '.cm_tab-content.cm_vehicle-search',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 650,
    },
    {
      name: 'HomeWheelPanel_tab',
      type: 'RequestPanel',
      location: '.cm_tab-content.cm_wheel-search',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: [...wheelsFields, 'brand_name'],
    },
    {
      name: 'HomeTirePanel_tab',
      type: 'RequestPanel',
      location: '.cm_tab-content.cm_tire-search',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: [...tiresFields, 'brand_name'],
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      isAlwaysColumnLayout: true,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
      isAlwaysColumnLayout: true,
      doNotRedirectOnVehicleSelect: isVehicleGalleryPage || isCategoryPage || isVehicleLandingPage,
    },
    {
      name: 'Garage',
      location: { insertAfter: '.navUser-item.form-li', class: 'cm_garage__desktop cm_mobile-hide' },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { insertBefore: '.addToCart-button-container' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'VehicleInfo',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '.body .page',
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () => window.location.pathname === categorySelectionPageUrl,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields: ['Universal'],
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};

/* eslint-disable */
globalThis.Convermax.snap_paymentEstimateData = {};
globalThis.Convermax.snap_as_low_as = (price, product_id, sku) => {
  $.ajax({
    method: 'POST',
    url: 'https://bigcommerce.snapfinance.com/api/as-low-as',
    data: {
      shop: '8zxb6tk',
      price: price,
      product_id: product_id,
      sku: sku,
    },
    cache: false,
    dataType: 'json',
    success: function (response) {
      var success = response.success;
      var status = response.status;
      if (status == 200 && success == true) {
        var obj = JSON.parse(JSON.stringify(response.data));
        var obj_items = JSON.parse(JSON.stringify(response.data.items));
        var totalCost = obj.totalCost;
        var costOfLease = obj.costOfLease;
        var paymentScheduleAmount = obj_items[0]['paymentScheduleAmount'];
        if (paymentScheduleAmount === 0) {
          $(`#card-info-snap_${product_id}`).hide();
          return;
        }
        var price = obj_items[0]['price'];
        $(`#payment_schedule_amount_id_${product_id}`).text(`$${paymentScheduleAmount}`);
        globalThis.Convermax.snap_paymentEstimateData[product_id] = {
          paymentScheduleAmount: obj_items[0]['paymentScheduleAmount'],
          price: price,
          frequency: 'W',
          totalCost: parseFloat(Number(totalCost).toFixed(2)),
          costOfLease: parseFloat(Number(costOfLease).toFixed(2)),
        };
      } else {
        $(`#card-info-snap_${product_id}`).hide();
        console.log('Error: Not getting as low as response!');
        var error_obj = JSON.parse(JSON.stringify(response.error));
        console.log(error_obj[0]);
      }
    },
  });
};

/* eslint-enable */
