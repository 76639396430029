//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-468:_2296,_5492,_2472,_7504,_4019,_476,_8996,_8543;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-468')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-468', "_2296,_5492,_2472,_7504,_4019,_476,_8996,_8543");
        }
      }catch (ex) {
        console.error(ex);
      }